<template>
   <v-row justify="center">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <v-card-title> Register new medication </v-card-title>
        <v-card-text>
                <v-select
                    label="Medication Name"
                    v-model="name"
                    :items="medicationList"
                    item-text="name"
                    item-value="name"
                >
                </v-select>

                <v-text-field
                  v-model="directive"
                  label="Directives"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="description"
                  label="Description"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="action"
                  label="Action"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="quantityGiven"
                  label="Quantity Given"
                  type="number"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="comment"
                  label="Comment"
                  required
                ></v-text-field>
                <v-datetime-picker label="Receive Date & Time"
                                   v-model="timeGiven" timePickerFormat="24hr"
                                   dateFormat="dd-MM-yyyy">
                    </v-datetime-picker>
                <v-btn
                  color="warning"
                  @click="register"
                >
                  Submit
                </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapActions } from 'vuex'
import medicationApi from '../../services/api/MedicationApi';

import moment from 'moment';

export default {
    name:'registerMedicationAdministration',
    data () {
      return {
        name: '',
        directive: '',
        description: '',
        action: '',
        comment: '',
        quantityGiven: 0,
        timeGiven: '',
        resident: '',
        user: '',
        medicationList: []
      }
    },
    computed: {
        ...mapGetters([
           'getLoggedUser'
        ]),
    },
     created() {
       this.getLoggedInUser();
       this.resident = this.$route.params.residentId;
       this.medicationForResident();
     },
    methods: {
      ...mapActions([
          'register_medication_administration',
          'getLoggedInUser',
      ]),
      register () {
        this.user = this.getLoggedUser.user.id;

        delete this.$data.medicationList;

        this.$data.timeGiven = this.formatDate(this.$data.timeGiven);
        this.register_medication_administration({
          ...this.$data
        })
        /* eslint-disable */
        .then(response => {
            this.$router.push('/medicationsAdministration')
        })
        .catch(error => {
            console.log(error)
        })
      },
      medicationForResident() {
        return new Promise((resolve, reject) => {
          medicationApi.getRegisteredMedication({page: '', count:'', id:this.resident, q:'', orderBy: '', order: ''})
            .then(medication => {
              this.medicationList = medication.result;
              resolve(this.medicationList);
            })
          .catch(error => {
            return reject(error)
          })
        })
      },
      formatDate(date) {
        date = moment(date).format('DD-MM-YYYY HH:mm');
        return date
      }
    },
}
</script>

<style lang="stylus" scoped>

</style>
